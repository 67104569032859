import React, { useContext } from 'react';

import { StoreContext } from '../context';
import { Loader, Message, StoreCardButtons } from './';
import { numberFormat } from '../utils/numberFormatter';

// @ts-ignore
import MUIDataTable from 'mui-datatables';
import { Box, Typography } from '@material-ui/core';

import { TableCell } from '@material-ui/core';

const oldRender = TableCell.render;

TableCell.render = function (...args) {
  const [props, ...otherArgs] = args;
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props;
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
  } else {
    return oldRender.apply(this, args);
  }
};

const StoreParts2 = () => {
  const ctx = useContext(StoreContext);

  const items = ctx?.items?.sort((a, b) =>
    a.displayName > b.displayName ? 1 : -1
  );

  // // console.log(items);

  const columns = [
    {
      name: 'itemCategoryCode',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'number',
      // label: 'Category',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'displayName',
      label: 'Part Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'customerUnitMeasure',
      label: 'Unit',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'customerPrice',
      label: 'Price',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => numberFormat('cur-display', value),
      },
    },
    {
      name: 'id',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customHeadLabelRender: () => '',
        customBodyRender: (value, tableMeta, updateValue) => (
          <StoreCardButtons item={tableMeta.rowData} />
        ),
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    tableBodyWidth: '500px',
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
  };

  return (
    <>
      {ctx.loading ? (
        <Loader />
      ) : ctx.error ? (
        <Message severity='error'>{ctx.error}</Message>
      ) : (
        items?.length > 0 && (
          <div style={{ minWidth: '100%' }}>
            <Box display='flex'>
              <Typography
                variant='caption'
                style={{
                  marginLeft: 'auto',
                  marginRight: 5,
                  marginTop: 5,
                  marginBottom: 5,
                }}
                color='error'
                align='right'
              >
                Use buttons here to search or filter items
              </Typography>
            </Box>
            <MUIDataTable
              title={'Parts & Extras'}
              data={items}
              columns={columns}
              options={options}
            />
          </div>
        )
      )}
    </>
  );
};

export { StoreParts2 };
