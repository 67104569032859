import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreContext } from '../context';
import {
  // addToCart,
  createOrder,
  // bcSalesQuote
} from '../actions';
import { Message, RejectDialog } from '../components';

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  IconButton,
  Box,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginRight: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: theme.palette.secondary.main,
  },
  rejectBtn: {
    marginRight: 'auto',
  },
}));

function DialogOrderAdminProcess({ eD }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);

  const cart = useSelector((state) => state.cart);
  const { cartInfo } = cart;

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [orderName, setOrderName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  // // console.log('eD', eD);

  useEffect(() => {
    if (ctx.isOrder) {
      setEditMode(true);
      setOrderName(cartInfo.orderName);
      setOrderNumber(cartInfo.orderNumber);
    }
  }, [ctx.isOrder, cartInfo, eD, dispatch, editMode]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (orderName?.length > 0) {
      const status =
        editMode && ctx.isAdmin && cartInfo.status !== 'Draft'
          ? 'Submitted'
          : 'Draft';
      dispatch(
        createOrder(
          cartInfo,
          orderName,
          orderNumber,
          status,
          eD,
          cartInfo?.rf?.file
        )
      );
      setOpen(false);
    }
  };

  const handleSubmit = async () => {
    if (orderName?.length > 0) {
      const status =
        editMode && ctx?.isAdmin && cartInfo?.status !== 'Draft'
          ? 'Approved'
          : 'Submitted';
      if (status === 'Approved') {
        dispatch(
          createOrder(
            cartInfo,
            orderName,
            orderNumber,
            status,
            eD,
            cartInfo?.rf?.file
          )
        );
        // dispatch(bcSalesQuote({ ...cartInfo, orderName: orderName }));
        setOpen(false);
      } else {
        dispatch(
          createOrder(
            cartInfo,
            orderName,
            orderNumber,
            status,
            eD,
            cartInfo?.rf?.file
          )
        );
        setOpen(false);
      }
    }
  };

  const dropDiffs = () => {
    const { screens } = cartInfo;
    const diffs = screens.filter(
      (e) => e.measurements.dropLeftDisplay !== e.measurements.dropRightDisplay
    );
    return diffs;
  };

  const dropDifferences = dropDiffs()?.length > 0 ? dropDiffs() : [];

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleClickOpen}
      >
        Process Order
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        style={{ minWidth: '450' }}
      >
        <DialogTitle>
          Process Order
          <IconButton
            onClick={handleClose}
            aria-label='close'
            className={classes.closeBtn}
          >
            <Cancel />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={!orderName?.length > 0}
            value={orderName}
            required
            margin='dense'
            id='name'
            label='Order Name (Required)'
            InputLabelProps={{
              shrink: true,
            }}
            type='text'
            fullWidth
            onChange={(e) => setOrderName(e.target.value)}
          />
          <TextField
            autoFocus={orderName?.length > 0}
            value={orderNumber}
            margin='dense'
            id='number'
            label='Order Number (Optional)'
            InputLabelProps={{
              shrink: true,
            }}
            type='text'
            fullWidth
            onChange={(e) => setOrderNumber(e.target.value)}
          />
          {dropDifferences?.length > 0 && (
            <div style={{ marginTop: 5 }}>
              <Message severity='error'>
                Please review the following:
                <br />
                These screens will be cut with a{' '}
                <strong>
                  <u>bottom slope</u>
                </strong>{' '}
                to match the difference listed in the measurements below.
              </Message>
              <TableContainer style={{ marginTop: 5 }} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Screen Name</TableCell>
                      <TableCell>Drop Left</TableCell>
                      <TableCell>Drop Right</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dropDifferences.map((dd) => (
                      <TableRow key={dd.screenId}>
                        <TableCell>{dd.screenName}</TableCell>
                        <TableCell>{dd.measurements.dropLeftDisplay}</TableCell>
                        <TableCell>
                          {dd.measurements.dropRightDisplay}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Box className={classes.rejectBtn}>
            <RejectDialog />
          </Box>
          <Button
            onClick={handleSave}
            variant='contained'
            color='primary'
            size='small'
            disabled={orderName?.length === 0}
          >
            Save Order
          </Button>
          {cartInfo?.status === 'Submitted' && (
            <Button
              onClick={handleSubmit}
              variant='contained'
              color='primary'
              size='small'
              disabled={orderName?.length === 0}
            >
              Approve Order
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { DialogOrderAdminProcess };
