import {
  CART_ADD_SCREEN,
  CART_ADD_ROOF,
  CART_ADD_PART,
  CART_ADD_FROM_ORDER,
  CART_REMOVE_SCREEN,
  CART_REMOVE_ROOF,
  CART_REMOVE_PART,
  CART_CLEAR_ITEMS,
} from '../constants';

export const cartReducer = (
  state = {
    cartInfo: { userDetails: {}, screens: [], roofs: [], parts: [] },
  },
  action
) => {
  switch (action.type) {
    case CART_ADD_SCREEN:
      console.log('CART_ADD_SCREEN', action.payload);
      const { screen } = action.payload;
      const screenExists = state.cartInfo.screens.find(
        (e) => e.screenId === screen.screenId
      );
      if (screenExists) {
        return {
          ...state,
          cartInfo: {
            ...state.cartInfo,
            screens: state.cartInfo.screens.map((e) =>
              e.screenId === screenExists.screenId ? screen : e
            ),
            totalPrice: action.payload.totalPrice,
          },
        };
      } else {
        return {
          ...state,
          cartInfo: {
            ...state.cartInfo,
            _id: action.payload._id,
            cartId: action.payload.cartId,
            orderId: action.payload.orderId,
            orderName: action.payload.orderName,
            orderNumber: action.payload.orderNumber,
            user: action.payload.user,
            userDetails: action.payload.userDetails,
            screens: [...state.cartInfo.screens, screen],
            totalPrice: action.payload.totalPrice,
            status: action.payload.status,
          },
        };
      }

    case CART_ADD_ROOF:
      console.log('CART_ADD_ROOF', action.payload);
      const { roof } = action.payload;
      const roofExists = state.cartInfo.roofs.find(
        (e) => e.roofId === roof.roofId
      );
      if (roofExists) {
        return {
          ...state,
          cartInfo: {
            ...state.cartInfo,
            roofs: state.cartInfo.roofs.map((e) =>
              e.roofId === roofExists.roofId ? roof : e
            ),
            totalPrice: action.payload.totalPrice,
          },
        };
      } else {
        return {
          ...state,
          cartInfo: {
            ...state.cartInfo,
            _id: action.payload._id,
            cartId: action.payload.cartId,
            orderId: action.payload.orderId,
            orderName: action.payload.orderName,
            orderNumber: action.payload.orderNumber,
            user: action.payload.user,
            userDetails: action.payload.userDetails,
            roofs: [...state.cartInfo.roofs, roof],
            totalPrice: action.payload.totalPrice,
            status: action.payload.status,
          },
        };
      }

    case CART_ADD_PART:
      console.log('CART_ADD_PART', action.payload);
      const { part } = action.payload;
      const partExists = state.cartInfo.parts.find((p) => p.id === part.id);
      if (partExists) {
        return {
          ...state,
          cartInfo: {
            ...state.cartInfo,
            parts: state.cartInfo.parts.map((p) =>
              p.id === partExists.id ? part : p
            ),
            totalPrice: action.payload.totalPrice,
          },
        };
      } else {
        return {
          ...state,
          cartInfo: {
            ...state.cartInfo,
            _id: action.payload._id,
            cartId: action.payload.cartId,
            orderId: action.payload.orderId,
            orderName: action.payload.orderName,
            orderNumber: action.payload.orderNumber,
            user: action.payload.user,
            userDetails: action.payload.userDetails,
            parts: [...state.cartInfo.parts, part],
            totalPrice: action.payload.totalPrice,
            status: action.payload.status,
          },
        };
      }

    case CART_ADD_FROM_ORDER:
      state = {
        cartInfo: { userDetails: {}, screens: [], roofs: [], parts: [] },
        orderInfo: { userDetails: {}, screens: [], roofs: [], parts: [] },
      };
      return {
        ...state,
        cartInfo: {
          cartId: action.payload.cartId,
          _id: action.payload._id,
          orderId: action.payload.orderId,
          orderName: action.payload.orderName,
          orderNumber: action.payload.orderNumber,
          user: action.payload.user,
          userDetails: action.payload.userDetails,
          screens: action.payload.screens,
          roofs: action.payload.roofs,
          parts: action.payload.parts,
          totalPrice: action.payload.totalPrice,
          status: action.payload.status,
          extraDetails: action.payload.extraDetails,
          createdAt: action.payload.createdAt,
          updatedAt: action.payload.updatedAt,
        },
        orderInfo: {
          cartId: action.payload.cartId,
          _id: action.payload._id,
          orderId: action.payload.orderId,
          orderName: action.payload.orderName,
          orderNumber: action.payload.orderNumber,
          user: action.payload.user,
          userDetails: action.payload.userDetails,
          screens: action.payload.screens,
          roofs: action.payload.roofs,
          parts: action.payload.parts,
          totalPrice: action.payload.totalPrice,
          status: action.payload.status,
          extraDetails: action.payload.extraDetails,
          createdAt: action.payload.createdAt,
          updatedAt: action.payload.updatedAt,
        },
      };

    case CART_REMOVE_SCREEN:
      return {
        ...state,
        cartInfo: {
          ...state.cartInfo,
          screens: state.cartInfo.screens.filter(
            (i) => i.screenId !== action.payload
          ),
        },
      };

    case CART_REMOVE_ROOF:
      return {
        ...state,
        cartInfo: {
          ...state.cartInfo,
          roofs: state.cartInfo.roofs.filter(
            (i) => i.roofId !== action.payload
          ),
        },
      };

    case CART_REMOVE_PART:
      return {
        ...state,
        cartInfo: {
          ...state.cartInfo,
          parts: state.cartInfo.parts.filter((i) => i.id !== action.payload),
        },
      };

    case CART_CLEAR_ITEMS:
      state = {
        cartInfo: { userDetails: {}, screens: [], roofs: [], parts: [] },
        orderInfo: { userDetails: {}, screens: [], roofs: [], parts: [] },
      };
      return state;

    default:
      return state;
  }
};
