import React from 'react';

import YouTube from 'react-youtube';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    // width: '100%',
    borderRadius: 6,
  },
  accordionDetails: {
    flexDirection: 'column',
  },
}));

const LibraryVideos = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant='h6' color='textPrimary'>
            Training
          </Typography>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-sales-content'
              id='training-sales-header'
            >
              <Typography>Sales</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'642imZSSorc'} />
              <YouTube videoId={'ETz5dLnADMY'} />
              <YouTube videoId={'EQaTO8KAIsg'} />
              <YouTube videoId={'ZavzEcIYziw'} />
              <YouTube videoId={'J2cKb5IMBso'} />
              <YouTube videoId={'VpwRutxY6uc'} />
              <YouTube videoId={'GmN_JReqi4s'} />
              <YouTube videoId={'8PpksrAP0cc'} />
              <YouTube videoId={'x8GbnyE4GoQ'} />
              <YouTube videoId={'j8pA4hQ7VpI'} />
              <YouTube videoId={'UH89nKIpww0'} />
              <YouTube videoId={'ey3XXPvySyQ'} />
              <YouTube videoId={'qCtG1SZqcTI'} />
              <YouTube videoId={'hcHHwDJhQb8'} />
              <YouTube videoId={'rweFw0J9RGM'} />
              <YouTube videoId={'-VfC62cgmp0'} />
              <YouTube videoId={'ChdL7Oov41s'} />
              <YouTube videoId={'F94wQasshiI'} />
              <YouTube videoId={'Ntsh7pT4McA'} />
              <YouTube videoId={'v9VPUvQGzGs'} />
              <YouTube videoId={'pXwy1xKR5YI'} />
              <YouTube videoId={'z6qZ0yP-02U'} />
              <YouTube videoId={'Gcbua4yEdfY'} />
              <YouTube videoId={'wKG4B2NDbkw'} />
              <YouTube videoId={'2mpcQiLQNvc'} />
              <YouTube videoId={'8ca836zi3cc'} />
              <YouTube videoId={'O5fBPswx-cY'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-ret-content'
              id='training-ret-header'
            >
              <Typography>Retractable Screens</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'-0LxiPHIGMA'} />
              <YouTube videoId={'08_s7mdQR2I'} />
              <YouTube videoId={'288hpW6r-NE'} />
              <YouTube videoId={'wj9_895jCDQ'} />
              <YouTube videoId={'SZmG_NhROGU'} />
              <YouTube videoId={'RrsDBlWJA3o'} />
              <YouTube videoId={'xp0CPFGKiPU'} />
              <YouTube videoId={'EXuRRV4GfIA'} />
              <YouTube videoId={'PiQLTqhBlaA'} />
              <YouTube videoId={'t6yaWjoTXGI'} />
              <YouTube videoId={'cAZBOd_ISOc'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-lou-content'
              id='training-lou-header'
            >
              <Typography>Louvered Roofs</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'JkJHiXFQKys'} />
              <YouTube videoId={'uw0PE0w1vL4'} />
              <YouTube videoId={'wF5wFiJhcW8'} />
              <YouTube videoId={'cAZBOd_ISOc'} />
              <YouTube videoId={'2W55tW_M2dk'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-pr-content'
              id='training-pr-header'
            >
              <Typography>Polycarbonate Roof</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'5_2SLZKKkmA'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-3sr-content'
              id='training-3sr-header'
            >
              <Typography>3 Season Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'p_GejtKWNuM'} />
              <YouTube videoId={'HHS-fQnnh0E'} />
              <YouTube videoId={'ZilYa5Wehqo'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-sr-content'
              id='training-sr-header'
            >
              <Typography>Screen Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'L24mj8ctej0'} />
              <YouTube videoId={'cnjWvTGxQ7I'} />
              <YouTube videoId={'Okxf1xwsQb0'} />
              <YouTube videoId={'QugTvMeN5II'} />
              <YouTube videoId={'sN2O3TJBrS8'} />
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant='h6' color='textPrimary'>
            Sales & Marketing
          </Typography>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-ret-content'
              id='sales-ret-header'
            >
              <Typography>Retractable Screens</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'UDbra_5Pbxo'} />
              <YouTube videoId={'Mh0fB1mm1m4'} />
              <YouTube videoId={'ZPOpusruT0k'} />
              <YouTube videoId={'fb11Dzu28uM'} />
              <YouTube videoId={'tqhHfYMWi10'} />
              <YouTube videoId={'J3JMrTbq4yQ'} />
              <YouTube videoId={'ls5iWMtrsEI'} />
              <YouTube videoId={'gnoPI7xfffc'} />
              <YouTube videoId={'aHbnaybhJYU'} />
              <YouTube videoId={'YBpojAnsOu8'} />
              <YouTube videoId={'8dmRT4AHK_4'} />
              <YouTube videoId={'4mcNq8aqu7I'} />
              <YouTube videoId={'hnLL6RCicao'} />
              <YouTube videoId={'AkfeHmevq4k'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-lou-content'
              id='sales-lou-header'
            >
              <Typography>Louvered Roofs</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'6iohbe-HEqs'} />
              <YouTube videoId={'aafZvN8uBc0'} />
              <YouTube videoId={'7E_4Ndh-GWw'} />
              <YouTube videoId={'JVH9OgadJuA'} />
              <YouTube videoId={'loWspdsSlPs'} />
              <YouTube videoId={'kByu1wAPD_s'} />
              <YouTube videoId={'QvDRPxGYQeg'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-3sr-content'
              id='sales-3sr-header'
            >
              <Typography>3 Season Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'giqeET1m9eE'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-sr-content'
              id='sales-sr-header'
            >
              <Typography>Screen Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'e-75fxS31z4'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-com-content'
              id='sales-com-header'
            >
              <Typography>Commercial</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'SCy7RUGPrE0'} />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-gen-content'
              id='sales-gen-header'
            >
              <Typography>General</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <YouTube videoId={'7PATyepiLMw'} />
              <YouTube videoId={'nux-SUelmJw'} />
              <YouTube videoId={'QdY-Y-NByk8'} />
              <YouTube videoId={'mVmhnTKHZs0'} />
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </>
  );
};

export { LibraryVideos };
